export const TextTheme = {
    sans_100: {
        fontSize: "10px",
        lineHeight: "12px",
        fontWeight: "425",
    },
    sans_150: {
        fontSize: "11px",
        lineHeight: "12px",
        fontWeight: "425",
    },
    sans_200: {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: "425",
    },
    sans_300: {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "425",
    },
    sans_350: {
        fontSize: "15px",
        lineHeight: "19px",
        fontWeight: "425",
    },
    sans_400: {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "425",
    },
    sans_500: {
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: "425",
    },
    sans_600: {
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "425",
    },
    sans_700: {
        fontSize: "22px",
        lineHeight: "26px",
        fontWeight: "425",
    },
    sans_800: {
        fontSize: "24px",
        lineHeight: "28px",
        fontWeight: "425",
    },
    sans_900: {
        fontSize: "27px",
        lineHeight: "32px",
        fontWeight: "425",
    },
    sans_1000: {
        fontSize: "32px",
        lineHeight: "36px",
        fontWeight: "425",
    },
    sans_1100: {
        fontSize: "40px",
        lineHeight: "44px",
        fontWeight: "425",
    },
    sans_1200: {
        fontSize: "48px",
        lineHeight: "52px",
        fontWeight: "425",
    },
    sans_1300: {
        fontSize: "64px",
        lineHeight: "72px",
        fontWeight: "425",
    },
    sans_100_med: {
        fontSize: "10px",
        lineHeight: "12px",
        fontWeight: "500",
    },
    sans_150_med: {
        fontSize: "11px",
        lineHeight: "12px",
        fontWeight: "500",
    },
    sans_200_med: {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: "500",
    },
    sans_300_med: {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "500",
    },
    sans_350_med: {
        fontSize: "15px",
        lineHeight: "19px",
        fontWeight: "500",
    },
    sans_400_med: {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "500",
    },
    sans_500_med: {
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: "500",
    },
    sans_600_med: {
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "500",
    },
    sans_700_med: {
        fontSize: "22px",
        lineHeight: "26px",
        fontWeight: "500",
    },
    sans_800_med: {
        fontSize: "24px",
        lineHeight: "28px",
        fontWeight: "500",
    },
    sans_900_med: {
        fontSize: "27px",
        lineHeight: "32px",
        fontWeight: "500",
    },
    sans_1000_med: {
        fontSize: "32px",
        lineHeight: "36px",
        fontWeight: "500",
    },
    sans_1100_med: {
        fontSize: "40px",
        lineHeight: "44px",
        fontWeight: "500",
    },
    sans_1200_med: {
        fontSize: "48px",
        lineHeight: "52px",
        fontWeight: "500",
    },
    sans_1300_med: {
        fontSize: "64px",
        lineHeight: "72px",
        fontWeight: "500",
    },
    sans_100_semi: {
        fontSize: "10px",
        lineHeight: "12px",
        fontWeight: "600",
    },
    sans_150_semi: {
        fontSize: "11px",
        lineHeight: "12px",
        fontWeight: "600",
    },
    sans_200_semi: {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: "600",
    },
    sans_300_semi: {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "600",
    },
    sans_350_semi: {
        fontSize: "15px",
        lineHeight: "19px",
        fontWeight: "600",
    },
    sans_400_semi: {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "600",
    },
    sans_500_semi: {
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: "600",
    },
    sans_600_semi: {
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "600",
    },
    sans_700_semi: {
        fontSize: "22px",
        lineHeight: "26px",
        fontWeight: "600",
    },
    sans_800_semi: {
        fontSize: "24px",
        lineHeight: "28px",
        fontWeight: "600",
    },
    sans_900_semi: {
        fontSize: "27px",
        lineHeight: "32px",
        fontWeight: "600",
    },
    sans_1000_semi: {
        fontSize: "32px",
        lineHeight: "36px",
        fontWeight: "600",
    },
    sans_1100_semi: {
        fontSize: "40px",
        lineHeight: "44px",
        fontWeight: "600",
    },
    sans_1200_semi: {
        fontSize: "48px",
        lineHeight: "52px",
        fontWeight: "600",
    },
    sans_1300_semi: {
        fontSize: "64px",
        lineHeight: "72px",
        fontWeight: "600",
    },
    sans_100_bold: {
        fontSize: "10px",
        lineHeight: "12px",
        fontWeight: "700",
    },
    sans_150_bold: {
        fontSize: "11px",
        lineHeight: "12px",
        fontWeight: "700",
    },
    sans_200_bold: {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: "700",
    },
    sans_300_bold: {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "700",
    },
    sans_350_bold: {
        fontSize: "15px",
        lineHeight: "19px",
        fontWeight: "700",
    },
    sans_400_bold: {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "700",
    },
    sans_500_bold: {
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: "700",
    },
    sans_600_bold: {
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "700",
    },
    sans_700_bold: {
        fontSize: "22px",
        lineHeight: "26px",
        fontWeight: "700",
    },
    sans_800_bold: {
        fontSize: "24px",
        lineHeight: "28px",
        fontWeight: "700",
    },
    sans_900_bold: {
        fontSize: "27px",
        lineHeight: "32px",
        fontWeight: "700",
    },
    sans_1000_bold: {
        fontSize: "32px",
        lineHeight: "36px",
        fontWeight: "700",
    },
    sans_1100_bold: {
        fontSize: "40px",
        lineHeight: "44px",
        fontWeight: "700",
    },
    sans_1200_bold: {
        fontSize: "48px",
        lineHeight: "52px",
        fontWeight: "700",
    },
    sans_1300_bold: {
        fontSize: "64px",
        lineHeight: "72px",
        fontWeight: "700",
    },
};

export const ButtonTheme = {
    sans_button_100: {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "600",
    },
    sans_button_200: {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "600",
    },
    sans_button_300: {
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: "600",
    },
    sans_button_100_light: {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "500",
    },
    sans_button_200_light: {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "500",
    },
    sans_button_300_light: {
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: "500",
    },
};

export const TallTextTheme = {
    sans_text_100: {
        fontSize: "12px",
        lineHeight: "20px",
        fontWeight: "425",
    },
    sans_text_200: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: "425",
    },
    sans_text_300: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "425",
    },
    sans_text_400: {
        fontSize: "18px",
        lineHeight: "26px",
        fontWeight: "425",
    },
};

export const ColorTheme = {
    Red: {
        color: "#ff3264",
    },
    Blue: {
        color: "#262626",
    },
    Black: {
        color: "#262626",
        opacityColor: "rgba(38, 38, 38, 0.15)",
    },
    Grey: {
        color: "#737373",
    },
    Grey1: {
        color: "#cccccc",
    },
    Grey2: {
        color: "#ebebeb",
    },
    Grey3: {
        color: "#f1f1f1",
    },
    Grey4: {
        color: "#f6f6f6",
    },
    Grey5: {
        color: "#fafafa",
    },
    Accent: {
        color: "#fdfaf9",
    },
    White: {
        color: "#ffffff",
    },
    Red1: {
        color: "#ed555b",
    },
    Yellow: {
        color: "#ffa01d",
    },
    Green: {
        color: "#30c300",
    },
    Red2: {
        color: "#e42151",
    },
    Khaki: {
        color: "#F7F4F0"
    }
};

export const GradientTheme = {
    Red: {
        background: "linear-gradient(93deg, #FF3266 0%, #E92C5A 49%, #D92364 100%);",
    },
    Black: {
        background: "linear-gradient(315deg, #1D1D1F 0%, #1D1D1F 49.5%, #545454 100%)",
    },
};
