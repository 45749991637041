import styled from "styled-components";
import { Link } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { motion } from "framer-motion";
import { TextTheme, ButtonTheme, ColorTheme, GradientTheme } from "../../globaStyledComponents/globalStyledComponentsStyling";

// Navbar component styling
interface NavbarProps {
    navbarMenu: boolean;
    location: string;
}
export const Navbar = styled.nav<NavbarProps>`
    position: relative;
    @media (max-width: 767px) {
        position: ${(props) => (props.navbarMenu ? "fixed" : "relative")};
        display: ${(props) => (props.location.includes("/search") || props.location === "/" ? "none" : "flex")};
    }
    z-index: 101;
    background: ${ColorTheme.White.color};
    margin: 0 auto;
    width: 100%;
    height: 60px;
    padding: 0 24px;
    display: ${(props) => (props.location.includes("/create-booking") || props.location.includes("/complete-booking") ? "none" : "flex")};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: none;
    @media (min-width: 768px) {
        //home padding
        display: ${(props) => (props.location.includes("/create-booking") || props.location.includes("/complete-booking")) && "none"};
        height: 80px;
        border-bottom: ${(props) =>
            props.location.includes("/your-stays") ||
            props.location.includes("/saved-listings") ||
            props.location.includes("/settings") ||
            props.location.includes("/edit-profile") ||
            props.location.includes("/profile") ||
            props.location.includes("/security-settings") ||
            props.location.includes("/payment-settings") ||
            props.location.includes("/dashboard") ||
            props.location.includes("/booking-request") ||
            props.location.includes("/payout-settings") ||
            props.location.includes("/payouts") ||
            props.location.includes("/payment-details") ||
            props.location.includes("/booking-receipt") ||
            props.location.includes("/your-calendar") ||
            props.location.includes("/your-listings") ||
            props.location.includes("/edit-listing") ||
            props.location.includes("/all-bookings") ||
            props.location.includes("/create-booking") ||
            props.location.includes("/complete-booking") ||
            props.location.includes("/manage-payments") ||
            props.location.includes("/host-conversation") ||
            props.location.includes("/conversation")
                ? `1px solid ${ColorTheme.Grey2.color}`
                : "none"};

        ${(props) => props.location.includes("/your-stays") && "padding: 0 32px;"}
        ${(props) => props.location.includes("/booking-request") && ""}
        ${(props) => props.location.includes("/referral-page") && "padding: 0 max(calc((100% - 1280px) / 2), 80px);"};
        ${(props) => props.location.includes("/settings") && "padding: 0 32px;"}
    }
    @media (min-width: 1024px) {
        ${(props) => props.location.includes("/settings") && "padding: 0 max(32px, calc((100% - 1024px) / 2));"}
    }
    @media (min-width: 1280px) {
        ${(props) => props.location.includes("/listing") && "padding: 0 calc(50% - 570px);"}
        ${(props) => props.location.includes("/your-stays") && "padding: 0 80px;"}
    }
`;

interface NavbarWrapperProps {
    location: string;
    navbarMenu: boolean;
}
export const NavbarWrapper = styled.div<NavbarWrapperProps>`
    position: ${(props) => (props.location.includes("/listing") ? "unset" : "fixed")};
    top: 0;
    width: 100%;
    max-height: 60px;
    background: ${ColorTheme.White.color};
    //make sure the mobilenavbar menu is behind if navbarmenu is opened on mobile;
    z-index: ${(props) => (props.navbarMenu ? "1002" : "100")};
    box-shadow: rgba(0, 0, 0, 0.065);
    ${(props) => (props.location.includes("/listing") || props.location.includes("create-listing")) && "display: none;"}

    @media (max-width: 767px) {
        ${(props) =>
            props.location.includes("/listing") ||
            props.location.includes("edit-profile") ||
            props.location.includes("your-inbox") ||
            props.location.includes("your-stays") ||
            props.location.includes("settings") ||
            props.location.includes("conversation") ||
            props.location.includes("saved-listings") ||
            props.location.includes("create-booking") ||
            props.location.includes("payment-settings") ||
            props.location.includes("your-booking") ||
            props.location.includes("manage-payments") ||
            props.location.includes("your-host-inbox") ||
            props.location.includes("payouts") ||
            props.location.includes("your-listings") ||
            props.location.includes("booking-request") ||
            props.location.includes("host-profile") ||
            props.location.includes("profile") ||
            props.location.includes("payment-details") ||
            props.location.includes("edit-listing") ||
            props.location.includes("your-calendar") ||
            props.location.includes("all-bookings") ||
            props.location.includes("referral-page")
                ? "display: none;"
                : ""}
    }

    @media (min-width: 768px) {
        max-height: 80px;
        /* Desktop nav bar shadow */
        box-shadow: ${(props) => (props.location.includes("-policy") || props.location.includes("-conditions") ? "none !important" : null)};
    }
`;

// Desktop search component
export const NavbarDesktopSearchbarWrapper = styled(motion.div)`
    @media (max-width: 767px) {
        display: none;
    }

    @media (max-width: 1023px) {
        width: 472px;
    }

    display: flex;
    height: 54px;
    width: 468px;
    border-radius: 100px;
    align-items: center;
    padding: 0 20px;
    transition: 125ms;
    cursor: pointer;
    position: relative;
    background: ${ColorTheme.White.color};
    box-shadow:
        1px 3px 16px 0px rgba(0, 0, 0, 0.1),
        0 0 2px 0 rgba(0, 0, 0, 0.04);

    &:hover {
        box-shadow:
            1px 3px 20px 0px rgba(0, 0, 0, 0.12),
            0 0 2px 0 rgba(0, 0, 0, 0.04);
    }
`;

export const NavbarDesktopMenuWrapper = styled.div`
    @media (max-width: 767px) {
        display: none;
    }

    display: flex;
    gap: 32px;
    margin: 15px auto 0;
    position: relative;
`;

interface NavbarDesktopMenuButtonProps {
    selected: boolean;
}
export const NavbarDesktopMenuButton = styled(Link)<NavbarDesktopMenuButtonProps>`
    background: none;
    border: none;
    color: ${(props) => (props.selected ? ColorTheme.Black.color : ColorTheme.Grey.color)};
    font-weight: ${(props) => (props.selected ? ButtonTheme.sans_button_100.fontWeight : ButtonTheme.sans_button_100.fontWeight)};
    font-size: ${ButtonTheme.sans_button_100.fontSize};
    text-decoration: none;
    line-height: ${ButtonTheme.sans_button_100.lineHeight};
    cursor: pointer;
`;

export const NavbarDesktopMenuButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 34px;
`;

export const NavbarDesktopMenuButtonBorderBottom = styled.div`
    width: 20px;
    height: 3px;
    background-color: ${ColorTheme.Black.color};

    // temporarily hidden
    display: none;
`;

export const NavbarDesktopSearchIcon = styled.img`
    height: 20px;
    width: 20px;
    margin: auto;
    object-fit: cover;
    position: realtive;
`;

export const NavbarLogoHomeButtonWrapper = styled.div`
    z-index: 10;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-self: flex-start;
    flex-grow: 1;
    flex-basis: 0;
`;
export const NavbarLogoHomeButton = styled.button`
    height: 32px;
    background: transparent;
    border: none;
    cursor: pointer;

    @media (min-width: 768px) {
        height: 36px;
    }
`;

export const NavbarLogoImage = styled.img`
    height: 32px;
    @media (min-width: 768px) {
        height: 36px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        display: none;
    }
`;
export const NavbarLogoImageTablet = styled.img`
    height: 32px;
    @media (min-width: 768px) {
        height: 36px;
    }
    @media (max-width: 767px), (min-width: 1025px) {
        display: none;
    }
`;

interface NavbarRightContentWrapperProps {
    location: string;
}
export const NavbarRightContentWrapper = styled.div<NavbarRightContentWrapperProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    flex-basis: 0;
`;

export const NavbarDesktopListYourPlaceWrapper = styled.button`
    @media (max-width: 1280px) {
        display: none;
    }
    padding: 11px 12px;
    border: none;
    font-size: ${ButtonTheme.sans_button_100.fontSize};
    font-weight: ${ButtonTheme.sans_button_100.fontWeight};
    line-height: ${ButtonTheme.sans_button_100.lineHeight};
    color: ${ColorTheme.Black.color};
    text-decoration: none;
    background-color: transparent;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
    cursor: pointer;

    &:hover {
        background-color: ${ColorTheme.Grey5.color};
    }
`;

export const SearchListingResultsContainerFilterIcon = styled.img`
    height: 20px;
    width: 20px;
    object-fit: cover;
`;

export const NavbarProfileNotificationWrapper = styled.button`
    /* Notifications hidden on mobile until complete */
    display: none;

    @media (min-width: 768px) {
        width: 40px;
        height: 40px;
        background-color: transparent;
        border: none;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: ${ColorTheme.Grey5.color};
        }
    }
`;

interface NavbarProfileUnreadNotificationAlertProps {
    guestMode: boolean;
}
export const NavbarProfileUnreadNotificationAlert = styled.div<NavbarProfileUnreadNotificationAlertProps>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => (props.guestMode ? ColorTheme.Red.color : ColorTheme.Blue.color)};
    align-self: flex-start;
    justify-self: flex-end;
`;

export const NavbarProfileNotificationImage = styled.img`
    height: 24px;
    width: 24px;
`;

export const NavbarProfileImageButton = styled.div`
    position: relative;
    z-index: 100;
    margin-left: 14px;
    width: 44px;
    height: 44px;
    transition: 125ms;
    border: 2px solid ${ColorTheme.White.color};
    box-shadow: 1px 3px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;

    &:hover {
        box-shadow: 1px 3px 20px 0px rgba(0, 0, 0, 0.12);
    }
`;

export const NavbarProfileImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
`;

export const NavbarMenuWrapper = styled.div`
    position: absolute;
    justify-self: flex-end;
    right: 0;
    top: 0;
    width: 100%;
    height: calc(100dvh - 60px);
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    background: ${ColorTheme.White.color};
    padding: 60px 24px 16px 24px;
    text-align: left;

    @media (min-width: 768px) {
        min-height: unset;
        height: fit-content;
        width: 252px;
        border-radius: 12px;
        padding: 8px 20px;
        margin-top: 56px;
        box-shadow: 1px 3px 12px 0px rgba(0, 0, 0, 0.1);
    }
`;

export const NavbarMenuTextItemWrapper = styled(Link)`
    height: 64px;
    display: flex;
    justify-content: space-between;
    text-decoration: none;

    padding: 13px 20px;
    margin: 0 -20px;

    &:hover {
        background-color: ${ColorTheme.Grey5.color};
    }
`;

export const NavbarMenuTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NavbarMenuText1 = styled.div`
    font-size: ${ButtonTheme.sans_button_100.fontSize};
    font-weight: ${TextTheme.sans_300.fontWeight};
    color: ${ColorTheme.Black.color};
    line-height: ${ButtonTheme.sans_button_100.lineHeight};
    margin-bottom: 4px;
    text-align: left;
`;

export const NavbarMenuText2 = styled.div`
    font-size: ${TextTheme.sans_200.fontSize};
    font-weight: ${TextTheme.sans_200.fontWeight};
    color: ${ColorTheme.Grey.color};
    line-height: ${TextTheme.sans_200.lineHeight};
    text-align: left;
`;

export const NavbarMenuRedDot = styled.div`
    position: flex;
    background-color: red;
    border-radius: 50%;
    margin-top: 5px;
    width: 8px;
    height: 8px;
    align-items: right;
`;

export const NavbarMenuBtn = styled.button`
    display: flex;
    height: 44px;
    font-size: ${ButtonTheme.sans_button_100.fontSize};
    font-weight: ${TextTheme.sans_300.fontWeight};
    color: ${ColorTheme.Black.color};
    line-height: ${ButtonTheme.sans_button_100.lineHeight};
    margin: 0;
    cursor: pointer;
    border: none;
    background: none;
    text-align: left;
    align-items: center;
    padding: 0;
    text-decoration: none;
    padding: 13px 20px;
    margin: 0 -20px;

    &:hover {
        background-color: ${ColorTheme.Grey5.color};
    }
`;

export const NavbarMenuItem = styled(Link)`
    display: flex;
    height: 44px;
    gap: 16px;
    font-size: ${ButtonTheme.sans_button_100.fontSize};
    font-weight: ${TextTheme.sans_300.fontWeight};
    color: ${ColorTheme.Black.color};
    line-height: ${ButtonTheme.sans_button_100.lineHeight};
    margin: 0;
    cursor: pointer;
    border: none;
    background: none;
    text-align: left;
    align-items: center;
    padding: 13px 20px;
    text-decoration: none;
    margin: 0 -20px;

    &:hover {
        background-color: ${ColorTheme.Grey5.color};
    }
`;

export const NavbarMenuDivider = styled.span`
    height: 1px;
    width: 100%;
    background: ${ColorTheme.Grey2.color};
    margin: 8px 0;
`;

export const NavbarMenuItemMainItemOutsideLink = styled.a`
    height: 44px;
    font-size: ${ButtonTheme.sans_button_100.fontSize};
    font-weight: ${TextTheme.sans_300.fontWeight};
    color: ${ColorTheme.Black.color};
    line-height: ${ButtonTheme.sans_button_100.lineHeight};
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    text-decoration: none;
    padding: 13px 20px;
    margin: 0 -20px;

    &:hover {
        background-color: ${ColorTheme.Grey5.color};
    }
`;

export const NavbarMenuItemMainIcon = styled.img`
    height: 20px;
    width: 20px;
    margin-top: 2px;
    object-fit: cover;
`;

export const NavbarHostMenuIcon = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: space-evenly;
    z-index: 10;

    justify-self: flex-end;
    flex-direction: column;
`;

export const NavbarCreateListingExitButton = styled.button`
    font-size: ${ButtonTheme.sans_button_200.fontSize};
    font-weight: ${TextTheme.sans_1000_semi.fontWeight};
    line-height: 0;
    color: ${ColorTheme.Black.color};
    cursor: pointer;
    background: none;
    border: 1px solid ${ColorTheme.Grey2.color};
    text-align: center;
    padding: 0 16px;
    position: absolute;
    top: 24px;
    right: 24px;
    height: 40px;
    border-radius: 100px;
    transition: 0.2s;
    &:hover {
        background: ${ColorTheme.Grey5.color};
    }
    @media (min-width: 768px) {
        top: 32px;
        right: 32px;
        padding: 0 24px;
    }
`;

// export const NavbarCreateListingExitButton = styled(Link)`
//     grid-row: 1/2;
//     grid-column: 1/2;
//     justify-self: flex-end;
//     font-size: ${TextTheme.sans_400.fontSize};
//     font-weight: ${TextTheme.sans_400.fontWeight};
//     line-height: ${TextTheme.sans_400.lineHeight};
//     color: ${ColorTheme.Blue.color};
//     margin: 0;
//     background: none;
//     border: none;
//     text-decoration: none;
// `

export const NavbarHostMenuIconBar = styled.div`
    width: 100%;
    height: 2px;
    background: ${ColorTheme.Black.color};
    transition: 0.1s;
`;

export const NavbarAutocompleteInput = styled(Autocomplete)`
    font-size: ${TextTheme.sans_300_semi.fontSize};
    font-weight: ${TextTheme.sans_300_semi.fontWeight};
    color: ${ColorTheme.Black.color};
    height: 32px !important;
    line-height: ${TextTheme.sans_300_semi.lineHeight};
    justify-self: center;
    border: none;
    background: none;
    outline: none;
    padding: 0;
    overflow: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    padding-bottom: 14px;
    width: 840px; /* To make the google autocomplete dropdown full width*/
    padding-left: 32px;
    margin-left: -32px;
    padding-right: calc(840px - 32px - 250px);
    &::-webkit-scrollbar {
        display: none;
    }
    &::placeholder {
        font-weight: ${TextTheme.sans_300.fontWeight} !important;
        color: ${ColorTheme.Grey.color} !important;
    }
`;

export const NavbarAutocompleteSearchText = styled.p`
    font-size: ${TextTheme.sans_350_semi.fontSize};
    font-weight: ${TextTheme.sans_350_semi.fontWeight};
    line-height: ${TextTheme.sans_350_semi.lineHeight};
    text-align: center;
    color: ${ColorTheme.Black.color};
    margin: 0;
    white-space: nowrap; // was text-wrap but saying unknown property
`;

export const NavbarSearbarGuestWrapper = styled.p`
    width: 100%;
    font-size: ${TextTheme.sans_200.fontSize};
    line-height: 17px;
    color: ${ColorTheme.Grey.color};
    font-weight: ${TextTheme.sans_200.fontWeight};
    text-align: left;
    margin: 0;
    white-space: nowrap; // was text-wrap but saying unknown property
`;

export const NavbarSearbarDatesWrapper = styled.p`
    width: 100%;
    font-size: ${TextTheme.sans_200.fontSize};
    line-height: 17px;
    color: ${ColorTheme.Grey.color};
    font-weight: ${TextTheme.sans_200.fontWeight};
    text-align: right;
    margin: 0;
    white-space: nowrap; // was text-wrap but saying unknown property
`;

export const SaveAndExitPopupWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 12px);
    position: fixed;
    background: ${ColorTheme.White.color};
    z-index: 102;
    left: 0;
    bottom: 0;
    border-radius: 14px 14px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 1px 3px 12px 0px rgba(0, 0, 0, 0.1);
    justify-content: space-between;

    @media (min-width: 768px) {
        width: 560px;
        border-radius: 14px;
        min-height: unset;
        height: 600px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const SaveAndExitPopupContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
`;

export const SaveAndExitPopupContentTitle = styled.p`
    font-size: ${TextTheme.sans_600_bold.fontSize};
    line-height: ${TextTheme.sans_600_bold.lineHeight};
    font-weight: ${TextTheme.sans_600_bold.fontWeight};
    color: ${ColorTheme.Black.color};
    margin: 0;

    @media (min-width: 768px) {
        font-size: ${TextTheme.sans_800_bold.fontSize};
        font-weight: ${TextTheme.sans_800_bold.fontWeight};
        line-height: ${TextTheme.sans_800_bold.lineHeight};
    }
`;
export const SaveAndExitPopupContentSubtitle = styled.p`
    margin: 0;
    margin-top: 12px;
    max-width: 240px;
    font-size: ${TextTheme.sans_400.fontSize};
    font-weight: ${TextTheme.sans_400.fontWeight};
    line-height: ${TextTheme.sans_400.lineHeight};
    color: ${ColorTheme.Black.color};
`;
export const SaveAndExitPopupContentImage = styled.img`
    height: 64px;
    width: 64px;
    object-fit: cover;
    margin-bottom: 32px;

    @media (min-width: 768px) {
        height: 80px;
        width: 80px;
    }
`;

export const SaveAndExitPopupButtonWrapper = styled.div`
    width: calc(100% - 48px);
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 768px) {
        border: none;
        padding: 24px 0;
        margin: 0;
    }
`;

export const SaveAndExitPopupCancelButton = styled.button`
    font-size: ${ButtonTheme.sans_button_200.fontSize};
    line-height: ${ButtonTheme.sans_button_200.lineHeight};
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    color: ${ColorTheme.Black.color};
    font-weight: ${ButtonTheme.sans_button_200.fontWeight};
`;

export const SaveAndExitPopupExitButton = styled.button`
    height: 48px;
    width: 104px;
    border-radius: 9px;
    color: ${ColorTheme.White.color};
    font-size: ${ButtonTheme.sans_button_100.fontSize};
    font-weight: ${ButtonTheme.sans_button_100.fontWeight};
    line-height: ${ButtonTheme.sans_button_100.lineHeight};
    text-decoration: none;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: none;
    background: ${ColorTheme.Blue.color};

    @media (min-width: 768px) {
        width: 104px;
    }
`;

export const SearchBarClearButton = styled.button`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${ColorTheme.Grey3.color};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    align-self: center;
    margin-left: auto;
`;

export const AddDatesPopupWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    width: calc(100% - 64px);
    max-width: 840px;
    height: 480px;
    background: ${ColorTheme.White.color};
    border-radius: 32px;
    box-shadow: 1px 3px 12px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 24px 8px;
    z-index: 11;

    @media (min-width: 1280px) {
        margin-left: auto;
    }
`;

export const AddDatesPopupTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-bottom: 32px;
`;

interface AddDatesPopupTitleProps {
    selected: boolean;
}
export const AddDatesPopupTitle = styled.p<AddDatesPopupTitleProps>`
    font-size: ${TextTheme.sans_300_semi.fontSize};
    font-weight: ${TextTheme.sans_300_semi.fontWeight};
    line-height: ${TextTheme.sans_300_semi.lineHeight};
    color: ${(props) => (props.selected ? ColorTheme.Black.color : ColorTheme.Grey.color)};
    padding-bottom: 7px;
    border-bottom: ${(props) => (props.selected ? `2px solid ${ColorTheme.Black.color}` : "none")};
    margin: 0;
`;

interface NotificationsTrayWrapperProps {
    notificationMenuButtonRefLeft: number;
}
export const NotificationsTrayWrapper = styled.div<NotificationsTrayWrapperProps>`
    position: fixed;
    left: 0;
    bottom: -10vh;
    width: 100%;
    z-index: 1000;
    height: 90vh;
    background: ${ColorTheme.White.color};
    border-radius: 14px 14px 0 0;
    box-shadow: 1px 3px 12px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    @media (min-width: 768px) {
        width: 400px;
        height: 650px;
        min-height: 750px;
        border-radius: 12px;
        left: 0;
        right: 0;
        top: 73px;
        margin-right: calc(100vw - ${(props) => props.notificationMenuButtonRefLeft + 40}px);
        margin-left: auto;
    }

    @media (min-width: 1643px) {
        margin-right: calc(100vw - ${(props) => props.notificationMenuButtonRefLeft + 40}px);
    }

    ::-webkit-scrollbar {
        // display: none;
    }
`;

export const NotificationsTitleWrapper = styled.div`
    display: flex;
    width: calc(100% - 48px);
    margin: 32px 24px;
    margin-bottom: 16px;
    align-items: center;

    @media (min-width: 768px) {
        margin: 24px 24px 12px 24px;
    }
`;

export const NotificationsTitle = styled.p`
    font-size: ${TextTheme.sans_600_semi.fontSize};
    line-height: ${TextTheme.sans_600_semi.lineHeight};
    font-weight: ${TextTheme.sans_600_semi.fontWeight};
    margin: 0;
    color: ${ColorTheme.Black.color};
`;

interface NotificationCardWrapperProps {
    read: boolean;
}
export const NotificationCardWrapper = styled.button<NotificationCardWrapperProps>`
    height: 100px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
    border: none;
    cursor: pointer;
    gap: 16px;
    text-align: left;
    background: ${(props) => (props.read ? `${ColorTheme.Grey4.color} !important` : "none")};

    &:hover {
        background: ${ColorTheme.Grey5.color};
    }
`;

export const NotificationCardTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;
`;

export const NotificationCardSubtitle = styled.p`
    font-size: ${TextTheme.sans_300.fontSize};
    font-weight: ${TextTheme.sans_300.fontWeight};
    color: ${ColorTheme.Black.color};
    line-height: ${TextTheme.sans_300.lineHeight};
    margin: 0;
    padding-right: 16px;

    strong {
        font-weight: ${TextTheme.sans_300_semi.fontWeight};
    }
`;

export const NotificationCardButton = styled.p`
    padding: 0;
    font-size: ${ButtonTheme.sans_button_100.fontSize};
    line-height: ${ButtonTheme.sans_button_100.lineHeight};
    font-weight: ${ButtonTheme.sans_button_100.fontWeight};
    color: ${ColorTheme.Grey.color};
    text-decoration: none;
    border: none;
    margin-top: 0;
`;

export const NotificationCardBackButton = styled.button`
    text-decoration: none;
    margin: 0;
    justify-self: flex-start;
    border: none;
    justify-self: flex-start;
    background: none;
    cursor: pointer;
    display: flex;
    margin-top: 2px;
    margin-right: 16px;
    padding: 0;

    @media (min-width: 768px) {
        display: none;
    }
`;

export const NotificationCardBackIcon = styled.img`
    height: 16px;
    object-fit: cover;
`;

export const NotificationCardImageWrapper = styled.div`
    height: 64px;
    width: 64px;
`;
export const NotificationCardTextWrapper = styled.div`
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const NotificationCardImage = styled.img`
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    background: ${ColorTheme.White.color};
`;

export const NotificationCardImageIconWrapper = styled.div`
    align-self: flex-end;
    justify-self: flex-end;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${ColorTheme.White.color};
    box-shadow: 1px 3px 12px 0px rgba(0, 0, 0, 0.1);
`;

export const NotificationCardImageIcon = styled.img`
    height: 14px;
    width: 14px;
    object-fit: cover;
`;

interface NavbarSearchbarPopDownProps {
    location: string;
}
export const NavbarSearchbarPopDown = styled(motion.div)<NavbarSearchbarPopDownProps>`
    position: relative;
    width: 100%;
    height: ${(props) => (props.location.includes("/search") || props.location === "/" ? "86px" : "68px")};
    background: ${ColorTheme.White.color};
    display: flex;
    justify-content: center;
    z-index: 102;
    align-items: ${(props) => (props.location.includes("/search") || props.location === "/" ? "center" : "start")};

    @media (min-width: 768px) {
        height: 96px;
        align-items: flex-start;
    }
`;

export const NavbarSearchbarMobileSearchButton = styled.button`
    position: relative;
    height: 56px;
    padding: 0px;
    width: calc(100% - 48px);
    background: ${ColorTheme.White.color};
    font-size: ${ButtonTheme.sans_button_100.fontSize};
    font-weight: ${TextTheme.sans_300.fontWeight};
    line-height: ${ButtonTheme.sans_button_100.lineHeight};
    color: ${ColorTheme.Grey.color};
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 100px;
    border: 0;
    box-shadow:
        1px 3px 16px 0px rgba(0, 0, 0, 0.1),
        0 0 2px 0 rgba(0, 0, 0, 0.025);
    flex-direction: row;
    @media (min-width: 768px) {
        display: none;
    }
`;

export const NavbarDesktopSearchIconArea = styled.div`
    position: absolute;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NavbarSearchbarMobileSearchTitleArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const SearchListingResultsContainerFiltersSelected = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: ${ColorTheme.Red.color};
    margin-right: 8px;
`;

export const SearchBarClearButtonIcon = styled.img`
    height: 10px;
    width: 10px;
`;
export const SearchListingResultsContainerFilterButton = styled.button`
    font-size: ${ButtonTheme.sans_button_100.fontSize};
    line-height: ${ButtonTheme.sans_button_100.lineHeight};
    font-weight: ${TextTheme.sans_300.fontWeight};
    color: ${ColorTheme.Black.color};
    display: flex;
    align-items: center;
    gap: 16px;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    z-index: 1100;
    cursor: pointer;
    @media (min-width: 768px) {
        display: none;
    }
`;

export const NavbarSearchbarMobileSearchTitle = styled.div`
    font-size: ${TextTheme.sans_350_semi.fontSize};
    line-height: ${TextTheme.sans_350_semi.lineHeight};
    font-weight: ${TextTheme.sans_350_semi.fontWeight};
    color: ${ColorTheme.Black.color};
`;

export const NavbarSearchbarMobileSearchSubTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${TextTheme.sans_200.fontSize};
    line-height: 17px;
    font-weight: ${TextTheme.sans_200.fontWeight};
    color: ${ColorTheme.Grey.color};
`;

export const NavbarSearchbarWrapperDesktop = styled(motion.div)`
    max-width: 840px;
    width: calc(100% - 64px);
    height: 64px;
    box-shadow:
        rgba(0, 0, 0, 0.1) 1px 3px 16px 0px,
        rgba(0, 0, 0, 0.04) 0px 0px 2px 0px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        display: none;
    }
`;

export const NavbarSearchbarWrapperDesktopSearchButton = styled(motion.button)`
    height: 48px;
    margin-left: auto;
    margin-right: 8px;
    background: ${GradientTheme.Red.background};
    border-radius: 100px;
    align-items: center;
    font-size: ${ButtonTheme.sans_button_200.fontSize};
    font-weight: ${ButtonTheme.sans_button_200.fontWeight};
    color: ${ColorTheme.White.color};
    cursor: pointer;
    line-height: ${ButtonTheme.sans_button_200.lineHeight};
    border: none;
    display: flex;
    width: 112px;
    gap: 10px;
    padding: 0 18px;
    z-index: 11;
`;

export const NavbarSearchbarWrapperDesktopSearchIcon = styled.img`
    width: 16px;
    height: 16px;
    object-fit: cover;
`;

export const NavbarSearchbarWrapperDesktopSearchLabel = styled.p`
    font-size: ${TextTheme.sans_200_semi.fontSize};
    font-weight: ${TextTheme.sans_200_semi.fontWeight};
    line-height: ${TextTheme.sans_200_semi.lineHeight};
    color: ${ColorTheme.Black.color};
    margin: 0;
`;

interface NavbarSearchbarWrapperDesktopDatesTextProps {
    selected: boolean;
}
export const NavbarSearchbarWrapperDesktopDatesText = styled.p<NavbarSearchbarWrapperDesktopDatesTextProps>`
    font-size: ${TextTheme.sans_300.fontSize};
    font-weight: ${(props) => (props.selected ? TextTheme.sans_300_semi.fontWeight : TextTheme.sans_300.fontWeight)};
    line-height: ${TextTheme.sans_300.lineHeight};
    color: ${(props) => (props.selected ? ColorTheme.Black.color : ColorTheme.Grey.color)};
    margin: 0;
    z-index: 10;
`;

export const NavbarSearchbarWrapperDesktopSearchWrapper = styled(motion.div)`
    flex: 1;
    height: 100%;
    margin-left: 32px;
    display: flex;
    justify-content: space-between;
    max-width: 280px;
`;
export const NavbarSearchbarWrapperDesktopLabelInputWrapper = styled.div`
    width: 93%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
`;
export const NavbarSearchbarWrapperDesktopDatesWrapper = styled.div`
    flex: 1;
    padding-left: 20px;
    height: 100%;
    padding: 13px 0 14px;
    display: flex;
    max-width: 138px;
`;
export const NavbarSearchbarWrapperDesktopDatesLabelInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2px;
`;

export const NavbarSearchbarWrapperDesktopSearchDivider = styled.div`
    width: 1px;
    height: 24px;
    background: ${ColorTheme.Grey2.color};
    margin: 0 32px 0 16px;
`;

interface NavbarSearchbarWrapperDesktopGuestsWrapperProps {
    fullWidth: boolean;
}
export const NavbarSearchbarWrapperDesktopGuestsWrapper = styled.div<NavbarSearchbarWrapperDesktopGuestsWrapperProps>`
    flex: 1;
    height: 100%;
    padding: 13px 0 14px;
    display: flex;
    max-width: ${(props) => (props.fullWidth ? "300px" : "150px")};
`;
export const NavbarSearchbarWrapperDesktopGuestsLabelInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2px;
`;

interface NavbarSearchbarWrapperDesktopGuestsTextProps {
    selected: boolean;
}
export const NavbarSearchbarWrapperDesktopGuestsText = styled.p<NavbarSearchbarWrapperDesktopGuestsTextProps>`
    font-size: ${TextTheme.sans_300.fontSize};
    font-weight: ${(props) => (props.selected ? TextTheme.sans_300_semi.fontWeight : TextTheme.sans_300.fontWeight)};
    line-height: ${TextTheme.sans_300.lineHeight};
    color: ${(props) => (props.selected ? ColorTheme.Black.color : ColorTheme.Grey.color)};
    margin: 0;
    z-index: 10;
    white-space: nowrap;
`;

export const DesktopNavbarSearchMenuRecommendedCitiesWrapper = styled(motion.div)`
    flex-wrap: wrap;
    display: flex;
    gap: 16px;
    position: absolute;
    top: 84px;
    right: 0;
    z-index: 11;
    left: 0;
    padding: 32px;
    border-radius: 32px;
    background: ${ColorTheme.White.color};
    box-shadow: 1px 3px 12px 0px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 64px);
    max-width: 840px;
    z-index: 1000;
`;

interface DesktopNavbarSearchMenuRecommendedCityProps {
    url: string;
}
export const DesktopNavbarSearchMenuRecommendedCity = styled.button<DesktopNavbarSearchMenuRecommendedCityProps>`
    width: 248px;
    height: 116px;
    flex: 1 1 200px;
    background: blue;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${(props) => props.url});
    background-size: cover;
    background-position: center center;
    border: none;
    display: flex;
    align-items: end;
    justify-content: flex-start;
    border-radius: 12px;
    cursor: pointer;
    padding: 16px;
    font-size: ${ButtonTheme.sans_button_200.fontSize};
    line-height: ${ButtonTheme.sans_button_200.lineHeight};
    color: ${ColorTheme.White.color};
    font-weight: ${ButtonTheme.sans_button_200.fontWeight};
`;

export const NavbarMiniDesktopSearchbarIconWrapper = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    position: absolute;
`;
export const NavbarMiniDesktopSearchbarIcon = styled.img`
    width: 20px;
    height: 20px;
    object-fit: cover;
`;
export const NavbarMiniDesktopSearchbarContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;

export const NavbarMiniDesktopSearchbarDateGuestsWrapper = styled.div`
    display: flex;
`;

export const NavbarMiniDesktopSearchbarDotDivider = styled.div`
    font-size: ${TextTheme.sans_200.fontSize};
    line-height: ${TextTheme.sans_200.lineHeight};
    color: ${ColorTheme.Grey.color};
    font-weight: ${TextTheme.sans_200.fontWeight};
    margin: 0 4px;
`;

export const NavbarCreateListingIcon = styled.svg`
    width: 16px;
    height: 16x;
    margin: 16px 12px 16px 0;
`;
