import { motion } from "framer-motion";
import AnimationWrapperForPopupPortal from "../../../components/animationWrapper/AnimationWrapperForPopup";
import { ClosePopupButton, ClosePopupIcon, PopupWrapper, PopupTitleWrapper, PopupTitle, EmailInputWrapper, EmailInput, EmailInputSubtitle, ButtonWrapper, CancelButton } from "../referralPageStyling";
import { ColorTheme } from "../../../components/globaStyledComponents/globalStyledComponentsStyling";
import { useState } from "react";
import ContinueBtn from "../../../components/btn/continueBtn/continueBtn";
import useClickOutside from "../../../hook/useClickOutside";

interface GetYourCodePopupProps {
    getCodePopup: boolean;
    handleClosePopup: () => void;
}

export default function GetYourCodePopup({ getCodePopup, handleClosePopup }: GetYourCodePopupProps) {
    const [emailNo, setEmailNo] = useState("");
    const getCodePopupRef = useClickOutside(getCodePopup, handleClosePopup);
    const [isContinueBtnLoading, setIsContinueBtnLoading] = useState(false);

    const checkEmail = async () => {
        //function for sending emails
        setIsContinueBtnLoading(true);
        console.log("checkEmail function triggered");
    };

    return (
        <motion.div animate={{ x: 0 }} transition={{ duration: 0.2, ease: "easeInOut" }} initial={{ x: 400 }}>
            <AnimationWrapperForPopupPortal>
                <PopupWrapper ref={getCodePopupRef}>
                    <ClosePopupButton onClick={handleClosePopup}>
                        <ClosePopupIcon src={"https://anystay-assets.s3.ap-southeast-2.amazonaws.com/Universal+icons/Cross.svg"} alt="Close button" />
                    </ClosePopupButton>
                    <PopupTitleWrapper>
                        <PopupTitle>Enter your email to get your discount code</PopupTitle>
                    </PopupTitleWrapper>
                    <EmailInputWrapper>
                        <EmailInput
                            value={emailNo}
                            onChange={(e) => {
                                setEmailNo(e.target.value);
                            }}
                            placeholder="Example@gmail.com"
                            name="email"
                        />
                        <EmailInputSubtitle>You agree to receiving occasional marking emails from us</EmailInputSubtitle>
                    </EmailInputWrapper>
                    <ButtonWrapper>
                        <CancelButton onClick={handleClosePopup}>Cancel</CancelButton>
                        <ContinueBtn
                            style={{ width: "unset", backgroundColor: `${ColorTheme.Black.color}` }}
                            background={"red"}
                            onClick={async () => {
                                checkEmail();
                            }}
                            isLoading={isContinueBtnLoading}
                            disabled={isContinueBtnLoading}
                        >
                            Get code
                        </ContinueBtn>
                    </ButtonWrapper>
                </PopupWrapper>
            </AnimationWrapperForPopupPortal>
        </motion.div>
    );
}
