import { SearchFilters } from "../../../interfaces/state/searchQuery";
export const calculateNumberOfFilterSelected = (searchFilters: SearchFilters): number => {
    let number = 0;
    if (searchFilters.bedrooms > 1) {
        number++;
    }
    if (searchFilters.bathrooms > 0) {
        number++;
    }
    if (searchFilters.priceRange.min !== 0 || searchFilters.priceRange.max !== 1000) {
        number++;
    }
    number += searchFilters.typeOfPlace.length + searchFilters.propertyType.length + searchFilters.optionsForYourStay.length + searchFilters.amenities.length + searchFilters.houseRules.length;
    return number;
};
